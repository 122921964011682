
import React, {useState, useEffect} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"
import SubmitDataField from "../../components/negosyonow/fields/submitdata"

const formatTools = require("../../../lib/formatTools")

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps")
const onpremAPI = require("../../../lib/requestOnprem")

const ENTITYID="nnpayment"


const searchFields = [
	{"label":"Last Update", "dbfield": "nnpayment_updated", "type": "datetime", "filtertype": "datetime"},
	{"label":"Payment Date", "dbfield": "nnpayment_date", "type": "datetime", "filtertype": "datetime"},
	{"label":"Validation Ref #", "dbfield": "nnpayment_validationref", "type": "text", "filtertype": "text"},
	{"label":"Validated", "dbfield": "nnpayment_validated", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Seminar Registration ID", "dbfield": "nnpayment_purposerefnum", "type": "integer", "filtertype": "text"},
	{"label":"Name", "dbfield": "nnpayment_notes", "type": "text", "filtertype": "text"},
	{"label":"Amount", "dbfield": "nnpayment_amount", "type": "currency", "filtertype": "currency"},
	{"label":"Status", "dbfield": "nnpaymentstatus_name", "type": "text", "filtertype": "text"},
	{"label":"Notes", "dbfield": "nnpayment_cancelreason", "type": "text", "filtertype": "text"},
];


const formFields = [
	[
		{"label":"Last Update", "field": "nnpayment_updated", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Payment Date", "field": "nnpayment_date", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Validation Ref #", "field": "nnpayment_validationref", "value": "", "input": "textbox", "mode": "readonly", "info":"Provided by payment clearing team like Onprem"},
		{"label":"Validated", "field": "nnpayment_validated", "value": "", "input": "checkbox", "mode": "readonly"},
		{"label":"Seminar Registration ID", "field": "nnpayment_purposerefnum", "value": "", "input": "textbox", "mode": "readonly", "info":"Use as reference # in payment"},
		{"label":"Details", "field": "nnpayment_submiturl", "value": "", "input": "textarea", "mode": "readonly"},
		{"label":"Status", "field": "nnpaymentstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":1,"display":"For Clearing/Active"},
			{"value":2,"display":"Cancelled"},
			{"value":3,"display":"For Review"}
		], "info":"For Clearing/Active means submit for bank clearing"},
		{"label":"Notes", "field": "nnpayment_cancelreason", "value": "", "input": "textarea", "mode": "normal"},
		{"label":"Seminar User ID", "field": "nnpayment_purposerefnum", "value": "", "input": "hidden", "mode": "readonly"},
	]
];




const SeminarpaymentsPage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [userformurl, setUserformurl] = useState("");
	const [userformdata, setUserformdata] = useState({});

	const [seminaruserid, setSeminaruserid] = useState(0);


	useEffect(() => {
		function loadFormdata(formurl)
		{
			negosyonowAPI.loadFormData(formurl, token).then(dataresponse => {
				setUserformdata(dataresponse);
			});
		}

		loadFormdata(userformurl)
	}, [userformurl, token]);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		//callback({"status":"Error", "message":"Debug"}); return;

		params.nnpayment_updated=formatTools.getDateStr();
		if (params.hasOwnProperty("nnpaymentstatus_id")) {
			if (params.nnpaymentstatus_id === "1") {
				relayPayment(function(relayresponse) {
					if (relayresponse.status === "OK") {
						var newpayment = false;
						if (relayresponse.hasOwnProperty("documentid")) {
							if (relayresponse.documentid > 0) {
								// add fields to params to reflect payment info/status
								params.nnpayment_validationref = ""+relayresponse.documentid;
								while (params.nnpayment_validationref.length < 8) {
									params.nnpayment_validationref = "0" + params.nnpayment_validationref;
								}
								if (relayresponse.statusid === 2) {
									newpayment = true;
									params.nnpayment_datevalidated = relayresponse.statusdate;
									params.nnpayment_validated = 1;
								}
							}
						}

						webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(updateresponse) {
							if (updateresponse.status === "OK") {
								// Check if payment cleared, and user needs to be updated
								if (newpayment) {
									updateUserPaidData(function(paymentres) {
										updateUserData(callback);
									});
									return;
								}
								updateUserData(callback);
								return;
							}
							callback(updateresponse);
						});
						return;
					}
					callback(relayresponse);
				});
				return;
			}
		}

		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				if (params.hasOwnProperty("nnpaymentstatus_id")) {
					if (params.nnpaymentstatus_id === 2) {
						// Payment rejected, update User data
						updateUserData(callback);
						return;
					}
				}
			}
			callback(response);
		}); // save record

	}
	function updateUserPaidData(callback)
	{
		if (seminaruserid > 0) {
			// Get seminar ID
			webappsAPI.loadData("nnpayment", {"filters":[[
				{"field":"nnpayment_purpose", "operation":"=", "value": "SEMINARFEE"},
				{"field":"nnpayment_purposerefnum", "operation":"=", "value": ""+seminaruserid},
				{"field":"nnpayment_validated", "operation":"=", "value": 1}
			]], "dbfieldlist": ["nnpayment_amount"]}, token).then(response => {
				console.log(response);
				if (response.status === "OK") {
					var totalamount = 0;
					var idx = 0;
					while (idx < response.data.length) {
						totalamount = totalamount + response.data[idx].nnpayment_amount;
						idx++;
					}

					webappsAPI.loadData("nnseminaruser", {"documentid":seminaruserid, "dbfieldlist": ["nnseminar_id"]}, token).then(userdataresponse => {
						var nnseminar_id = 0;
						var nnseminar_regfee = 0;
						if (userdataresponse.status === "OK") {
							//response.data[0].nnseminar_id
							if (userdataresponse.count > 0) {
								nnseminar_id = userdataresponse.data[0].nnseminar_id;
							}
						}

						webappsAPI.loadData("nnseminar", {"documentid":nnseminar_id, "dbfieldlist": ["nnseminar_regfee"]}, token).then(feedataresponse => {
							var updateparam = {
								"documentid": seminaruserid,
								"nnseminaruser_paidamount": totalamount
							};
							if (feedataresponse.status === "OK") {
								//response.data[0].nnseminar_id
								if (feedataresponse.count > 0) {
									nnseminar_regfee = feedataresponse.data[0].nnseminar_regfee;
								}
							}
							if (nnseminar_regfee>0 && totalamount>=nnseminar_regfee) {
								updateparam.nnseminaruser_confirmed = 1;
								updateparam.nnseminaruser_dateconfirmed = formatTools.getDateStr();
							}

							webappsAPI.saveRecord("nnseminaruser", updateparam, token).then(saveresponse => {
								callback(saveresponse);
							});
						}); // load seminar
					}); // load seminaruser
				} // if db query ok
				callback(response);
			});  // load seminaruserid
		} else {
			callback({"status":"Error", "message":"Invalid seminar payment data"});
		}
	}
	function updateUserData(callback)
	{
		if (seminaruserid > 0) {
			// Get seminar ID
			webappsAPI.loadData("nnseminaruser", {"documentid":seminaruserid, "dbfieldlist": ["nnseminar_id"]}, token).then(response => {
				if (response.status === "OK") {
					if (response.count > 0) {
						negosyonowAPI.buildEntitybyParam({"id":response.data[0].nnseminar_id, "mode": "user"},token, "seminar").then(buildresponse => {
							callback({
								"status":"OK",
								"message":"Updating records"
							});
						});
						return;
					}
				}
				callback(response);
			});  // load seminaruserid
		} else {
			callback({"status":"Error", "message":"Invalid seminar payment data"});
		}
	}

	function relayPayment(callback)
	{
		var paymentdate = "";
		var paymentamount = 0;
		var seminartitle = "";
		var payor = "";
		var receiptfname = "";
		var receiptbase64 = "";
		var receipttype = "";
		var label = "";
		var tmpidx = 0;

		var idx = 0;
		while (idx < userformdata.fields.length) {
			label = userformdata.fields[idx].label;
			tmpidx = label.indexOf(" ");
			if (tmpidx > 0) {
				label = label.substring(0, tmpidx);
			}
			if (userformdata.fields[idx].label === "Seminar Name") {
				seminartitle = userformdata.fields[idx].value;
			} else if (userformdata.fields[idx].label === "Full Name") {
				payor = userformdata.fields[idx].value;
			} else if (userformdata.fields[idx].label === "Amount") {
				paymentamount = parseFloat(userformdata.fields[idx].value);
			} else if (label === "Date/Time") {
				paymentdate = userformdata.fields[idx].value;
			} else if (label === "Source") {
				receipttype = userformdata.fields[idx].value;
			} else if (label === "Receipt") {
				if (userformdata.fields[idx].value.hasOwnProperty("fname") && userformdata.fields[idx].value.hasOwnProperty("data")) {
					receiptfname = userformdata.fields[idx].value.fname;
					receiptbase64 = userformdata.fields[idx].value.data;
				}
			}
			idx++;
		}
		if (receiptbase64.length < 1 || receiptfname.length < 1) {
			callback({"status":"Error", "message":"No receipt provided"});
			return;
		}

		onpremAPI.addPayment({
			"doctypeid": 4, 					// 1:bus,2:pickup,3:delivery,4:seminar
			"bankcode": "CBC",					// TODO: hardcoded for now
			"bankacccountno": "20-7043878-1",	// TODO: hardcoded for now

			"paymentdate":paymentdate,
			"amount":paymentamount,
			"docrefno": ""+seminaruserid,
			"payor":payor,
			"notes":seminartitle, // seminar title/name

			"receiptfname":receiptfname,
			"receiptbase64":receiptbase64,
			"receipttype":receipttype,
		}).then(response => {
			// Update nnpayment
			callback(response);

		});

	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var statusidval = "";
		var summaryval = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nnpaymentstatus_id") {
				statusidval = parseInt(""+mainform[idx].value,10);
			} else if (mainform[idx].field === "nnpayment_cancelreason") {
				summaryval = mainform[idx].value;
			} else if (mainform[idx].field === "nnpayment_purposerefnum") {
				setSeminaruserid(parseInt(mainform[idx].value, 10));
			}
			idx++;
		}
		if (statusidval === 2 && summaryval.length < 1) {
			return {"status":"Error", "message":"Please provide reason for cancelling in the deposit"};
		}
		return {"status":"OK"};
	}

	function customEditFieldInfo(inputlist, idx)
	{
		const fieldlist = ["nnpaymentstatus_id", "nnpayment_cancelreason"];
		if (!fieldlist.includes(inputlist[idx].field)) {
			return inputlist[idx];
		}
		var statusidval = 0;
		var tmpidx = 0;

		while (tmpidx < inputlist.length) {
			if (inputlist[tmpidx].field === "nnpaymentstatus_id") {
				if (inputlist[tmpidx].modified) {
					return inputlist[idx];
				}
				statusidval = parseInt(""+inputlist[tmpidx].value,10);
				break;
			}
			tmpidx++;
		}

		if (statusidval === 3) {
			// For Review
			return inputlist[idx];
		}

		var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
		tmpfield.mode = "readonly";
		return tmpfield;
	} // customEditFieldInfo


	function customFieldFragment(inputlist, idx)
	{
		if (inputlist[idx].field === "nnpayment_submiturl") {
			if (inputlist[idx].value) {
				if (inputlist[idx].value !== "") {
					setUserformurl(inputlist[idx].value);
					return displayFormData(inputlist[idx].label, userformdata);
				}
			} else {
				return <></>
			}
		}
		return null;
	}

	function displayFormData(title, formdata)
	{
		const labelformatlist = {
			"Date/Time of Deposit":"datetime",
			"Amount":"currency"
		};
		return <SubmitDataField
					datatitle={title}
					formdata={formdata}
					labelformatlist={labelformatlist}
				/>
	}

	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={false}
				allowdelete={false}
				mobilerowtitlefield={["nnpayment_notes"]}
				searchFields={searchFields}
				editFields={formFields}
				userSearchParam={{"orderby":"nnpayment_updated desc"}}
				userSearchFilter={[{"field":"nnpayment_purpose", "operation":"=", "value": "SEMINARFEE"}]}

				customSubmit={customSubmit}
				customFieldFragment={customFieldFragment}
				customEditFieldInfo={customEditFieldInfo}
				customValidateValues={customValidateValues}

				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default SeminarpaymentsPage;
