
// TODO

const url = {
	host: "api.onpremconcepts.com",
	protocol: "https",
	apicode:"BZNGSCG86OMF4XCBABCP",
	apikey:"EhIZ5qtmsuUUOd7zNo35A578k4E8LLKHbb7aUT0",
	suffix: ""
};

module.exports = {
	url
}

