
import React from "react"

import * as styles from "./submitdata.module.css"


const formatTools = require("../../../../lib/formatTools");

const SubmitDataField = ({datatitle, formdata, labelformatlist}) => {

	function generatefielddata (fielddata)
	{
		var valuestr = "";
		var labelstr = fielddata.label;
		if (fielddata.label.indexOf("(") > 0) {
			labelstr = fielddata.label.substring(0,fielddata.label.indexOf("("));
		}

		var valuetype = typeof fielddata.value;
		if (valuetype === "object") {
			if (fielddata.value.hasOwnProperty("display")) {
				// Address
				valuestr = fielddata.value.display;
			} else if (fielddata.value.hasOwnProperty("data")) {
				valuestr = fielddata.value.fname;
				if (fielddata.value.mimetype.substring(0, 6)==="image/") {
					const imgsrc = "data:"+fielddata.value.mimetype+";base64,"+fielddata.value.data
					return <tr>
							<td valign="top" colspan="2">
								{labelstr} (click to Zoom)
								<div>
									<img alt={""} className={styles.imagedata} onKeyDown={(e)=>{/* Prevents warning */}} onClick={()=>{window.open(imgsrc, "_blank")}} src={imgsrc} />
								</div>
							</td>
						</tr>
				}
				// file data
			}
		} else if (valuetype !== "undefined") {
			valuestr = fielddata.value;
		} else {
			return <tr>
				<td colspan={2}><h3>{labelstr}</h3></td>
			</tr>
		}
		if (labelformatlist.hasOwnProperty(labelstr)) {
			valuetype = labelformatlist[labelstr];

			if(valuetype === "date") {
				valuestr = formatTools.datestr(valuestr);
			} else if(valuetype === "datetime") {
				valuestr = formatTools.datetimestr(valuestr);
			} else if(valuetype === "currency") {
				valuestr = formatTools.currencystr(valuestr);
			}
		}

		return <tr>
			<td valign="top">{labelstr}</td>
			<td valign="top">{valuestr.split("\n").map((linedata)=> {
				return <>{linedata}<br/></>
			})}</td>
		</tr>
	}


	return <>
		{datatitle.length > 0 && <h2 className={styles.formtitle}>{datatitle}</h2>}
		<div className={styles.formholder}>
			{formdata.hasOwnProperty("fields") ? <>
				<table>
					{formdata.fields.map((fielddata,fieldidx)=>{
						return generatefielddata(fielddata);
					})}
				</table>
			</>
			:
				<>Loading...</>
			}
		</div>
	</>
}


export default SubmitDataField;
