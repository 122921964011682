

const apiconfig = require("../config/onprem-config");
const requestJSON = require("./requestJSON");

const getURL = (entity, action) => {
	return apiconfig.url.protocol+"://"+apiconfig.url.host+"/"+entity+"/"+action;
}

const sendRequest = (entity, action, payload) => {
	return requestJSON.send( getURL(entity, action) , "POST", {
		"apicode":apiconfig.url.apicode,
		"apikey":apiconfig.url.apikey,
		"data": payload
	});

}


const addPayment = (payload) => {
	//return sendRequest( "paymentbeta", "add", payload);
	return sendRequest( "payment", "add", payload);
}


module.exports = {
	addPayment
}
